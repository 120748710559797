<template>
    <div class="login">
        <van-popup class="vanPopup" style="z-index: 9" v-model="show" :close-on-click-overlay="false">
            <p class="dengRu">登录</p>
            <!--            <img class="close" @click="close()" src="../assets/images/VoteTemp-1/close.png" alt="">-->

            <!--            <img class="close"  src="../assets/images/VoteTemp-1/close.png" alt="">-->
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
                <div class="qrCode">
                    <img class="phone" src="../assets/images/VoteTemp-1/phone.png" alt="">
                    <el-form-item label="" prop="phone" class="itemConIng">
                        <el-input v-model="ruleForm.phone" type="number" maxlength="11" placeholder="请输入手机号"
                                  autocomplete="off"
                                  clearable></el-input>
                    </el-form-item>
                    <el-button type="primary" size="normal" @click="getCode('ruleForm')" v-if="timeFlag">
                        发验证码
                    </el-button>
                    <el-button type="info" disabled v-else>{{ time }}s
                    </el-button>
                    <div id="c1" ref="c1"></div>
                </div>
                <div class="qrCode">
                    <img class="code" src="../assets/images/VoteTemp-1/code.png" alt="">
                    <el-form-item label="" prop="code">
                        <el-input v-model="ruleForm.code" type="number" placeholder="请输入验证码"
                                  onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="6" clearable></el-input>
                    </el-form-item>
                </div>
                <el-button style="background-image: none;" class="submits" type="primary"
                           @click="submitForm('ruleForm')">{{ loginText }}
                </el-button>
            </el-form>
        </van-popup>
    </div>
</template>

<script>
import {Toast} from 'vant'
import {qrCode, loginPhone} from '../assets/api/vote'
import {rsaJsonData, rsaPublicData} from "../assets/js/rsa"
import {encrypt} from "../assets/js/rsa-dz"
import encryptLong from "../assets/js/JsencryptLong"

export default {
    name: "login",
    data() {
        return {
            show: true,
            phone: '',
            code: '',
            time: 120,
            timer: null,
            timeFlag: true,
            ruleForm: {
                phone: "",
                code: "",
            },
            rules: {
                phone: [
                    {
                        pattern: /^1[3-9](\d{9})$/,
                        message: "请输入正确的手机号",
                        trigger: ["blur"],
                    },
                    {max: 11, message: "请输入11位手机号", trigger: "change"},
                    {required: true, message: "请输入手机号", trigger: "blur"},
                ],
                code: [{required: true, message: "请输入验证码", trigger: "blur"}],
            },
            loginText: "登录",
            loginLoading: false,
            smsParams: null
        }
    },
    created() {
        this.getParams()
    },
    methods: {
        getParams() {
            let params = this.$route.query
            let keyList = Object.keys(params)
            let idList = [
                {key: 'voteId', value: '1'},
                {key: 'signId', value: '2'},
                {key: 'surveyId', value: '3'},
                {key: 'qaId', value: '4'},
                {key: 'lotteryId', value: '5'},
            ]
            keyList.map(key => {
                idList.map(item => {
                    if(key === item.key) {
                        this.smsParams = {
                            actType: item.value,
                            actId: params[key]
                        }
                        return false
                    }
                })
            })
            console.log(this.smsParams)
            console.log('login', this.$route.query)

        },
        close() {
            this.show = false
        },
        // 顶象验证
        getCode(formName) {
            let that = this
            this.$refs[formName].validateField("phone", (phoneError) => {
                if (phoneError) {
                    console.log(phoneError)
                    return false;
                } else {
                    console.log(22222)
                    this.$nextTick(() => {
                        console.log(this.$refs['c1'])
                        console.log(document.getElementById("c1"))
                        let myCaptcha = _dx.Captcha(document.getElementById("c1"), {
                            appId: "c9df629fddc9f71df2033f8ee291fba5", //appId，在控制台中“应用管理”或“应用配置”模块获取
                            // apiServer: 'cap.dingxiang-inc.com',
                            style: "popup",
                            fail: (err) => {
                                console.log('errr')
                                console.log(err)
                                myCaptcha.reload()
                            },
                            success: (res) => {
                                // console.log(111111111111111111)
                                // console.log(res)
                                let token = res;
                                myCaptcha.hide();
                                console.log(rsaPublicData(this.ruleForm.phone.toString().trim()))
                                // return false
                                let params = {
                                    mobile: rsaPublicData(this.ruleForm.phone.toString().trim()),
                                    dxToken: token,
                                    ...this.smsParams
                                    // type: "sms_login_code",
                                };
                                qrCode(params).then((res) => {

                                    console.log(res)
                                    if (res.code == 200) {
                                        // 验证码发送逻辑
                                        that.timeFlag = false;
                                        Toast("短信验证码发送成功,请注意查收")
                                        // 开始倒计时
                                        that.timer = setInterval(() => {
                                            if (that.time > 0) {
                                                that.time--;
                                            } else {
                                                that.time = 120;
                                                that.timeFlag = true;
                                                clearInterval(that.timer);
                                                that.timer = null;
                                                that.ruleForm.code = ''
                                            }
                                        }, 1000);
                                    } else {
                                        that.timeFlag = true;
                                        Toast(res.msg)
                                    }
                                });
                            },
                        });
                        myCaptcha.show();
                    })
                    // 先进行人机验证

                }
            });
        },

        // 手机号登录成为壹点用户并获取信息
        submitForm(formName) {
            // 授权并登录 先做非空验证
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loginText = "正在登录...";
                    this.loginLoading = true;
                    console.log(encrypt(this.ruleForm.phone.toString().trim()))
                    let obj = {
                        mobile: encrypt(this.ruleForm.phone.toString().trim()),
                        // mobile: this.ruleForm.phone.toString().trim(),
                        verifyCode: this.ruleForm.code.toString().trim(),
                        // sourceCode: 'activityplat'
                        // mobile: '20000045678',
                        // smsCode: '133333',
                    };
                    if (this.bindInfo.openId) {
                        obj = {
                            ...obj,
                            ...this.bindInfo
                        }
                    }
                    console.log(obj)
                    loginPhone(obj).then((res) => {
                        this.loginText = "登录";
                        this.loginLoading = false;
                        if (res.data.code === 0) {
                            // 拿到access_token res.data.data.access_token
                            // this.dataDetail.access_token = res.data.data.access_token;
                            // this.dataDetail.userId = res.data.data.userId;
                            // this.dataDetail.phone = this.ruleForm.phone.toString().trim();
                            localStorage.setItem("token", res.data.data.access_token);  // 壹点token
                            this.$store.commit('CHANGE_TOKEN', res.data.data.access_token);
                            console.log(res.data.data.access_token)
                            Toast(res.data.message)
                            Toast("登录成功")
                            this.show = false
                        } else {
                            Toast(res.data.message)
                        }
                    });
                } else {
                    return false;
                }
            });
        },
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        },
        bindInfo() {
            return this.$store.getters.getBindInfo
        }
    },
}
</script>

<style scoped lang="less">
@import "../assets/css/theme";

.module-theme(@theme, @tag) {
    .close {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .submits {
        width: calc(100% - 100px) !important;
        height: 50px !important;
        margin: 40px 50px 0 !important;
        border-radius: 25px !important;
        font-size: 16px !important;
        color: #FFFFFF;
        text-align: center;
        background-image: none;
        display: flex;
        justify-content: center;
        border: none !important;
        outline: none !important;
        line-height: 27px !important;
    }
    .dengRu {
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        text-align: center;
        margin: 40px 0 20px;
    }
    .van-overlay {
        z-index: 9 !important;
    }
    .van-popup {
        width: 355px;
        height: 330px;
        z-index: 2009 !important;
        margin: auto;
        border-radius: 6px;
    }
    .phone, .code {
        width: 24px;
        height: 24px;
        display: block;
        margin-top: 10px;
        margin-right: 10px;
    }
}
</style>
<style lang="less">
.qrCode {
    width: calc(100% - 60px);
    height: 48px;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    /*border-bottom: 1px solid #999;*/
    border-bottom: 1px solid #F5F5F5;
}

.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    content: '*';
    color: rgba(1, 1, 1, 0);
    margin-right: 4px;
}

.el-form-item__label {
    width: 80px !important;
    line-height: 48px;
    text-align: left;
}

.el-form-item__content {
    margin-left: 0px !important;
}

.el-form {
    margin-top: 10px;
}

.el-form-item {
    /*width: calc(100% - 15px);*/
    height: 38px;
    margin: 0;
}

.el-input__inner {
    outline: none !important;
    border: none !important;
    box-sizing: border-box !important;
}

.el-input {
    width: 130px !important;
    border: none !important;
    height: 48px !important;
    line-height: 48px !important;
    text-align: left;
}

.el-input__suffix {
    display: none;
}

.el-input--suffix .el-input__inner {
    padding-right: 0 !important;
}

.el-form-item__error {
    z-index: 999;
    left: 15px;
    top: 75%;
}

.el-button--primary {
    background-color: #CB0709 !important;
}

.el-button {
    height: 28px !important;
    margin-top: 14px !important;
    width: 74px !important;
    background-image: none;
    display: flex;
    justify-content: center;
    border: none !important;
    outline: none !important;
    border-radius: 14px !important;
    font-size: 14px;
    line-height: 5px !important;
    margin-left: 26px !important;
}

//.dx_captcha_loading_overlay {
//    z-index: 3000!important;
//}

.dx_captcha_scratch-style-popup {
    z-index: 3000 !important;
}

.dx_captcha_clickword-style-popup {
    z-index: 3000 !important;
}
.dx_captcha_basic-style-popup {
    z-index: 3000 !important;
}

//.dx_captcha_loading_overlay {
//    z-index: 5000;
//}
</style>
