<template>
    <div id="VoteTemp-1" :class="theme || 'theme-red-CB0709'" v-title :data-title="voteInfo.title && voteInfo.title">
        <login v-if="show"></login>
        <div class="VoteTemp-1">
            <!--            <img class="banner" src="../../../assets/images/VoteTemp-1/banner.png" alt="">-->
            <img class="banner" :src="voteInfo.themeImg + `?imageslim`" alt="">
            <!--            活动评选数据-->
            <div class="starCon">
                <div class="starTop">
                    <img class="star" src="../../../assets/images/VoteTemp-1/star.png?imageslim" alt="">
                    <p class="starTitle">{{ voteInfo.title }}</p>
                </div>
                <div class="voteNumbers">
                    <div class="voteNumCon">
                        <p class="p1">{{ count.voteCountTotal }}</p>
                        <p class="p2">点赞数</p>
                    </div>
                    <div class="voteNumCon">
                        <p class="p1">{{ count.voteOptionCount }}</p>
                        <p class="p2">作品数</p>
                    </div>
                    <div class="voteNumCon">
                        <p class="p1">{{ count.visitCount }}</p>
                        <p class="p2">访问量</p>
                    </div>
                </div>
                <p v-if="voteInfo.voteTimeStatus==3" class="tine-box">活动已结束</p>
                <p v-else class="tine-box">点赞{{ voteInfo.voteTimeStatus == 1 ? '结束' : '开始' }}倒计时<span
                        class="numP">{{ day || 0 }}</span>天<span class="numP">{{ time || 0 }}</span>时<span class="numP">{{
                        min || 0
                    }}</span>分<span
                        class="numP">{{ second }}</span>秒</p>
            </div>
            <div class="relate-lottery" v-show="voteInfo.relateLotteryId">
                <div>获得抽奖机会后可前往抽奖</div>
                <div @click="toLottery">前往抽奖</div>
            </div>
            <!--            活动规则-->
            <div class="ruleCon">
                <div class="ruleTop">
                    <img class="arrow" src="../../../assets/images/VoteTemp-1/leftArrows.png?imageslim" alt="">
                    <span class="rule">活动规则</span>
                    <img class="arrow" src="../../../assets/images/VoteTemp-1/rightArrows.png?imageslim" alt="">
                </div>
                <div class="ruleItem" v-for="item in ruleList">
                    <img class="strTime" :src="item.img" alt="">
                    <p class="ruleP1">{{ item.ruleP1 }}</p>
                    <p class="ruleP2">{{ item.ruleP2 }}</p>
                </div>
            </div>
            <div class="tabCon">
                <!--                活动介绍-->
                <div class="introCon" v-if="active==1">
                    <div class="introTop">
                        <img class="arrow" src="../../../assets/images/VoteTemp-1/leftArrows.png?imageslim" alt="">
                        <span class="intro">活动介绍</span>
                        <img class="arrow" src="../../../assets/images/VoteTemp-1/rightArrows.png?imageslim" alt="">
                    </div>
                    <div class="introItem1 text-content html" v-html="voteInfo.introduction"></div>
                    <!--                    <div class="introItem1">-->
                    <!--                        <div class="introItem">-->
                    <!--                            <img class="dot" src="../../../assets/images/VoteTemp-1/dot.png" alt="">-->
                    <!--                            <p>活动主题</p>-->
                    <!--                        </div>-->
                    <!--                        <p class="introP1">为庆祝xxxx节日，弘扬xxxx精神，由xxxx主办的xxxx评选活动。</p>-->
                    <!--                    </div>-->
                    <!--                    <div class="introItem2">-->
                    <!--                        <div class="introItem">-->
                    <!--                            <img class="dot" src="../../../assets/images/VoteTemp-1/dot.png" alt="">-->
                    <!--                            <p>活动时间</p>-->
                    <!--                        </div>-->
                    <!--                        <div class="introP1">-->
                    <!--                            <p class="introP2">报名时间：</p>-->
                    <!--                            <p>2021-08-12 14:00至2021-08-20 14:00</p>-->
                    <!--                            <p class="introP2">投票时间：</p>-->
                    <!--                            <p>2021-08-12 14:00至2021-08-20 14:00</p>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="introItem1">-->
                    <!--                        <div class="introItem">-->
                    <!--                            <img class="dot" src="../../../assets/images/VoteTemp-1/dot.png" alt="">-->
                    <!--                            <p>参赛对象</p>-->
                    <!--                        </div>-->
                    <!--                        <p class="introP1">凡是xxxx皆可参加</p>-->
                    <!--                    </div>-->
                    <!--                    <div class="introItem2">-->
                    <!--                        <div class="introItem">-->
                    <!--                            <img class="dot" src="../../../assets/images/VoteTemp-1/dot.png" alt="">-->
                    <!--                            <p>活动流程</p>-->
                    <!--                        </div>-->
                    <!--                        <div class="introP1">-->
                    <!--                            <p class="introP2">第一阶段：报名阶段</p>-->
                    <!--                            <p>报名时间：8月12日-8月14日</p>-->
                    <!--                            <p>1.点击投票页“立即报名”按钮即可报名</p>-->
                    <!--                            <p>2.禁止上传任何非法，侵权内容</p>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="introItem1">-->
                    <!--                        <div class="introItem">-->
                    <!--                            <img class="dot" src="../../../assets/images/VoteTemp-1/dot.png" alt="">-->
                    <!--                            <p>评选规则</p>-->
                    <!--                        </div>-->
                    <!--                        <p class="introP1">投票规则：每个微信每天可投1票，只能为同一选手投1票</p>-->
                    <!--                        <p class="introP1">投票声明：严禁任何作弊行为，系统会进行自动监测，一经发现立即取消投票资格</p>-->
                    <!--                    </div>-->
                </div>
                <!--                排行榜-->
                <div class="rankCon" v-if="active==2">
                    <div class="introTop">
                        <img class="cup" src="../../../assets/images/VoteTemp-1/cup.png?imageslim" alt="">
                        <span class="intro">人气排行榜</span>
                    </div>
                    <div class="introItem" v-for="(item,index) in rankList">
                        <div class="introItem1">
                            <img class="cupImg" v-if="index==0" src="../../../assets/images/VoteTemp-1/first.png?imageslim"
                                 alt="">
                            <img class="cupImg" v-if="index==1" src="../../../assets/images/VoteTemp-1/second.png?imageslim"
                                 alt="">
                            <img class="cupImg" v-if="index==2" src="../../../assets/images/VoteTemp-1/third.png?imageslim"
                                 alt="">
                            <p class="cupImg" v-if="index>2">{{ index + 1 }}</p>
                            <div class="cupName over-one">{{ item.optionName }}</div>
                        </div>
                        <div class="cupNum" v-if="item.voteCount>=0&&item.voteCount<10000">{{ item.voteCount }}票</div>
                        <div class="cupNum" v-else-if="item.voteCount>10000">
                            {{ (item.voteCount / 10000).toFixed(2) }}万票
                        </div>
                    </div>
                    <div class="more" v-if="moreFlag" @click="more">查看更多</div>
                </div>
                <!--                投票-->
                <div class="voteCon" v-if="active==0">
                    <div class="voteTop">
                        <img class="searchImg" src="../../../assets/images/VoteTemp-1/search.png?imageslim" alt="">
                        <input class="input" placeholder="请输入名称或编号" v-model="searchCon" type="text">
                        <div class="send" @click="search()">搜索</div>
                    </div>
                    <div class="tab-box" v-if="classList.length > 0">
                        <div @click="changeTab('', 0)"
                             :class="`tab-bar ${classIndex === 0 && 'focus-tab'}`">全部</div>
                        <div v-for="(item, index) of classList"
                             :key="index"
                             @click="changeTab(item.id, index + 1)"
                             :class="`tab-bar ${classIndex === index + 1 && 'focus-tab'}`">{{item.className}}</div>
                    </div>
                    <div class="introItem">
                        <div class="voteItem" v-for="(item,index) in voteList" :key="index">
                            <div class="topNum">{{ item.optionSerial }}号</div>
                            <img class="play" v-show="voteInfo.voteType === '2'"
                                 src="../../../assets/images/VoteTemp-1/play.png?imageslim" alt="">
                            <img @click="goDetail(item)" class="voteHead" :src="item.optionImg + `?imageslim`" alt="">
                            <div class="vote-info">
                                <div class="over-one">{{item.optionName}}</div>
                            </div>
                            <div class="vote-name over-two">{{item.optionAuthor}}</div>
                            <div class="vote-num">热度：{{ item.voteCount }}</div>
                            <div class="vote-comp over-two">单位：{{ item.optionCompany }}</div>
<!--                            <div class="vote-btn-red">投票</div>-->
<!--                            <p class="voteNum">{{ item.voteCount }}票</p>-->
<!--                            <p class="voteTitle">{{ item.optionName }}</p>-->
                            <div class="voteImg" @click="vote(item.optionId, item)">点赞</div>
<!--                            <img class="voteImg" @click="vote(item.optionId, item)"-->
<!--                                 src="https://image.dzplus.dzng.com/2024/09/13/1726220602oa1hmgfstxqimage.png" alt="">-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footBg">
                <!--                <p class="footRule" v-if="active==1">活动最终解释权归xxx所有</p>-->
                <p class="footRule" v-if="active==0 && !requestFlag">已加载完，没有啦！</p>
            </div>
            <div class="foot">
                <van-tabbar v-model="active" @change="onChange" active-color="#333"
                            inactive-color="rgba(51, 51, 51, 0.57)">
                    <van-tabbar-item>
                        <span style="font-size: 12px;">点赞</span>
                        <template #icon="props">
                            <img class="tab-image" :src="props.active ? icon.active : icon.inactive"/>
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item icon="search">
                        <span style="font-size: 12px;">介绍</span>
                        <template #icon="props">
                            <img class="tab-image" :src="props.active ? icon1.active : icon1.inactive"/>
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item icon="setting-o">
                        <span style="font-size: 12px;">排行</span>
                        <template #icon="props">
                            <img class="tab-image" :src="props.active ? icon2.active : icon2.inactive"/>
                        </template>
                    </van-tabbar-item>
                </van-tabbar>
            </div>
        </div>
    </div>
</template>

<script>
import login from '../../../components/login'
import {
    getVoteInfo,
    getVoteOptionInfo,
    getVoteStatistInfo,
    operateVote,
    getVoteRankList,
    getOptionClassList
} from '../../../assets/api/vote'
import wxShare from '../../../assets/js/wx-share'
import {isWeixin, loginWx, WXauthorizeWx, getIsApp} from '../../../assets/js/wx-authorize'
import {Toast} from "vant";
import lodash from 'lodash'
import store from "../../../store";
import qs from "qs";
import {mapState} from "vuex";

export default {
    name: "VoteTemp-1",
    components: {
        login,
    },
    data() {
        return {
            active: 0,
            icon: {
                inactive: 'https://img.qiluyidian.net/Y7hrRH_1638950109936.png?imageslim',
                active: 'https://img.qiluyidian.net/spWBrk_1638950126299.png?imageslim',
            },
            icon1: {
                inactive: 'https://img.qiluyidian.net/fWyrJW_1638950363085.png?imageslim',
                active: 'https://img.qiluyidian.net/jSe8Jt_1638950385900.png?imageslim',
            },
            icon2: {
                inactive: 'https://img.qiluyidian.net/WPwZ2S_1638950406185.png?imageslim',
                active: 'https://img.qiluyidian.net/k7s24i_1638950419884.png?imageslim',
            },
            voteInfo: {},
            //    规则列表
            ruleList: [
                {
                    img: 'https://img.qiluyidian.net/nEZwA2_1638955743750.png?imageslim',
                    ruleP1: '点赞开始',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/K7QrjA_1638955762393.png?imageslim',
                    ruleP1: '点赞截止',
                    ruleP2: '2021-08-12',
                },
                {
                    img: 'https://img.qiluyidian.net/Kt68Bj_1638955779662.png?imageslim',
                    ruleP1: '点赞规则',
                    ruleP2: '每个用户每天可点10赞',
                },
            ],
            // 数据访问量啥啥啥
            count: {},
            //距离结束的时间
            intervalToEnd: '',
            second: '',
            min: '',
            time: '',
            day: '',
            voteGroupList: [],
            //选项信息分页
            pageNum: 1,
            flag: false,
            noMore: false,
            isLoad: true,
            requestFlag: true,
            //接口返回接收
            groupId: '',
            //排行榜页码
            pageNo: 1,
            moreFlag: true,

            //    介绍隐藏
            showIntro: false,
            //    排行隐藏
            showRank: false,
            rankList: [],
            //    投票隐藏
            showVote: false,
            searchCon: '',
            voteList: [],
            show: false,
            id: '1',
            classId: '',
            classList: [],
            classIndex: 0
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', lodash.debounce(this.scrollHandle, 200))
    },
    activated() {
        this.pageNum = 1
    },
    created() {
        this.getParams()
        // window.scrollTo(0, 0)


        // if (!this.token) {
        //     if (isWeixin()) {
        //         loginWx()
        //     } else if(getIsApp()) {
        //         console.log(11111111111111111111111)
        //         this.$bridge.callHandler('h5GetApptoken')
        //         this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
        //             console.log(data)
        //             if(data) {
        //                 this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
        //                 this.getParams()
        //             }
        //
        //             // this.getUserInfo(JSON.parse(data).token);
        //         })
        //         // this.$bridge.callHandler('h5GetApptoken', {}, res => {
        //         //     console.log('res---------------------')
        //         //     console.log(res.data)
        //         //     if(res) {
        //         //         this.$store.commit('CHANGE_TOKEN', res)
        //         //     }else {
        //         //         this.$bridge.callHandler('needLogin')
        //         //     }
        //         // })
        //     }else {
        //         this.show = false
        //     }
        // }
    },
    mounted() {
        // console.log(getIsApp())
        this.$nextTick(() => {
            window.addEventListener('scroll', lodash.debounce(this.scrollHandle, 200)) // 滑动使用滚动条
            setTimeout(() => window.scroll(0, 0), 0)
        })


    },
    methods: {
        getLogin() {
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')

            } else if (!this.token) {
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = false
                }
            } else {
                // this.getParams()
            }
        },
        getParams() {
            // console.log(this.$route.query)
            console.log(this.$store.state.user.authType == 2 ? '/activity/wechatLogin' : '/activity/qlydLogin')
            this.id = this.$route.query.voteId
            this.getVoteInfo()
            this.getVoteStatistInfo()
            this.getClassList()
            // this.getVoteRankList()
        },
        // 滚动分页加载
        scrollHandle() {
            // 获取页面页面的滚动高度
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            // 获取页面滚动距离顶部的高度,  window.pageYOffse 兼容苹果
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
            // 获取页面的可视高度
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
            // 当然我们也可以滚动距离底部还有一定距离的时候执行加载
            if (scrollTop + clientHeight >= scrollHeight - 156) {
                // 距离底部还有20的时候执行数据加载
                if (this.flag) return
                this.flag = true
                this.pageNum++   // 加载下一页
                if (!this.noMore) {
                    this.getVoteOptionInfo()
                    // this.flag = false
                }
            }
        },
        // change() {
        //     this.$store.commit('CHANGE_THEME', 'theme-sky-blue')
        //     console.log(this.$store)
        // }
        onChange(index) {
            this.active = index
            // console.log(index)
        },
        //   获取投票信息
        getVoteInfo() {
            let params = {
                "voteId": this.id,
            };
            getVoteInfo(params).then(res => {
                // console.log(res.data)
                if (res.code == 200) {
                    console.log(res.data)
                    if(res.data.authType) {
                        this.$store.commit('SET_AUTH_Type', res.data.authType)
                    }
                    this.voteInfo = res.data
                    this.ruleList[0].ruleP2 = res.data.startTime
                    this.ruleList[1].ruleP2 = res.data.endTime
                    this.ruleList[2].ruleP2 = `每个用户每天可点${res.data.voteLimit}赞`
                    if (res.data.voteTimeStatus == 1) {
                        this.formatSeconds(res.data.intervalToEnd)
                        this.getLogin()
                    } else if (res.data.voteTimeStatus == 2) {
                        this.formatSeconds(res.data.intervalToBegin)
                        this.getLogin()
                    }
                    this.voteGroupList = res.data.voteGroupList
                    this.getVoteOptionInfo()
                    if (isWeixin()) {
                        let {title, weShareSummary, weShareImg} = res.data

                        let wxlink = window.location.href
                        let [params, query] = wxlink.split('?')
                        query = qs.parse(query)
                        delete query.code
                        delete query.state
                        console.log(qs.stringify(query))
                        // console.log(params + '?' + qs.stringify(query))
                        wxlink = params + '?' + qs.stringify(query)
                        wxShare.loadWxConfig(title, weShareSummary, wxlink, weShareImg)
                        // wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                    }
                }
            }).catch(err => {
            })
        },
        //   活动评选数据 (访问量啥啥啥)
        getVoteStatistInfo() {
            let params = {
                "voteId": this.id,
            };
            getVoteStatistInfo(params).then(res => {
                console.log(res.data)
                if (res.code == 200) {
                    this.count = res.data
                }
            }).catch(err => {
            })
        },
        changeTab(id, index) {
            this.classId = id
            this.classIndex = index
            this.voteList = []
            this.pageNum = 1
            this.requestFlag = true
            this.getVoteOptionInfo()
        },
        async getClassList() {
            const { data } = await getOptionClassList(this.id)
            this.classList = data || []
        },
        //   获得投票的选项信息
        getVoteOptionInfo() {
            let pageSize = 10
            if (this.voteGroupList.length > 0) {
                this.groupId = this.voteGroupList[0].groupId
            }
            console.log('groupId', this.groupId)
            console.log('pageNum', this.pageNum)
            let params = {
                "voteId": this.id,
                "groupId": this.groupId,
                "pageNo": this.pageNum,
                'searchWord': this.searchCon,
                classId: this.classId,
                pageSize
            };
            if (this.requestFlag) {
                getVoteOptionInfo(params).then(res => {
                    console.log(res.data)
                    if (res.code == 200) {
                        if (res.data) {
                            if (res.data.length < pageSize) {
                                this.requestFlag = false
                            }
                            console.log('=====', res.data)
                            this.voteList = this.voteList.concat(res.data)
                            this.flag = false // 数据加载完成之后放开节流阀
                        } else {
                            this.requestFlag = false
                        }
                    }
                }).catch(err => {
                })
            }

        },
        //   获得投票的选项信息
        // 搜索Who
        search() {
            this.voteList = []
            this.pageNum = 1
            this.requestFlag = true
            this.getVoteOptionInfo()
        },
        // 给...投票
        vote(id, item) {
            console.log(this.authType)
            console.log(id)
            if (this.voteInfo.voteTimeStatus == 3) {
                this.$toast('活动已结束')
                return false
            }
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            this.operateVote(id, item)
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        this.operateVote(id, item)
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')
            } else if (!this.token) {
                if (isWeixin()) {
                    loginWx()
                } else {
                    if(this.authType == 2) {
                        this.$toast('请在微信端打开')
                    }else {
                        this.show = true
                    }
                }
            } else {
                this.operateVote(id, item)
            }

            // if (!this.token) {
            //     if (isWeixin()) {
            //         loginWx()
            //     } else if (getIsApp()) {
            //         this.$bridge.callHandler('h5GetApptoken')
            //         this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
            //             console.log(data)
            //             if (data) {
            //                 this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
            //             }
            //
            //             // this.getUserInfo(JSON.parse(data).token);
            //         })
            //
            //     } else {
            //         this.show = false
            //     }
            // } else {
            //     console.log(this.token)
            //     this.operateVote(id, item)
            // }
            // if (!this.token) {
            //     if (isWeixin()) {
            //         loginWx()
            //     } else {
            //         // console.log(111)
            //         this.show = true
            //     }
            // }else{
            //     //    投票接口
            //     console.log(this.token)
            //     this.operateVote(id, item)
            // }
        },
        // 投票接口
        operateVote(id, item) {
            let params = {
                "voteId": this.id,
                "optionId": id
            };
            operateVote(params).then(res => {
                console.log('521', res.data)
                if (res.code == 200) {
                    Toast('点赞成功')
                    item.voteCount++
                } else if (res.code == 517) {
                    Toast('活动参与次数已用尽')
                } else {
                    Toast(res.msg)
                }
            }).catch(err => {
            })
        },
        // 排行榜接口
        getVoteRankList() {
            let pageSize = 10
            let params = {
                "voteId": this.id,
                'pageNo': this.pageNo,
                pageSize
            };
            if (this.moreFlag) {
                getVoteRankList(params).then(res => {
                    console.log(res.data)
                    if (res.code == 200) {
                        if (res.data) {
                            if (res.data.length < pageSize) {
                                this.moreFlag = false
                            }
                            // this.rankList = [...res.data, ...res.data]
                            if (this.pageNo == 1) {
                                this.rankList = res.data
                            } else {
                                this.rankList = this.rankList.concat(res.data)
                            }
                        } else {
                            Toast('已加载完全部')
                            this.moreFlag = false
                        }
                    }
                }).catch(err => {
                    Toast(err)
                })
            }
        },

        // 点击加载更多按钮
        more() {
            //    排行页码加1
            this.pageNo++
            this.getVoteRankList()
        },
        // 进入个人详情页
        goDetail(item) {
            console.log('item', this.$route.query)
            let {title, weShareSummary, weShareImg} = this.voteInfo
            this.$router.push({
                path: '/vote1-details',
                query: {
                    relateLotteryId: this.voteInfo.relateLotteryId,
                    voteTimeStatus: this.voteInfo.voteTimeStatus,
                    authType: this.voteInfo.authType,
                    ...item,
                    ...this.$route.query,
                    title, weShareSummary, weShareImg
                }
            })
        },
        //倒计时时间转换
        formatSeconds(value) {
            var theTime = parseInt(value);// 需要转换的时间秒
            var theTime1 = 0;// 分
            var theTime2 = 0;// 小时
            var theTime3 = 0;// 天
            if (theTime > 60) {
                theTime1 = parseInt(theTime / 60);
                theTime = parseInt(theTime % 60);
                if (theTime1 > 60) {
                    theTime2 = parseInt(theTime1 / 60);
                    theTime1 = parseInt(theTime1 % 60);
                    if (theTime2 > 24) {
                        //大于24小时
                        theTime3 = parseInt(theTime2 / 24);
                        theTime2 = parseInt(theTime2 % 24);
                    }
                }
            }
            var result = '';
            if (theTime > 0) {
                result = "" + parseInt(theTime);
                this.second = result
            }
            if (theTime1 > 0) {
                // result = "" + parseInt(theTime1) + "分" + result;
                result = "" + parseInt(theTime1);
                this.min = result
            }
            if (theTime2 > 0) {
                // result = "" + parseInt(theTime2) + "小时" + result;
                result = "" + parseInt(theTime2);
                this.time = result
            }
            if (theTime3 > 0) {
                // result = "" + parseInt(theTime3) + "天" + result;
                result = "" + parseInt(theTime3);
                this.day = result
            }
            // return result;
        },
        toLottery() {
            console.log(this.voteInfo)
            this.$router.push({
                path: '/draw1',
                query: {
                    lotteryId: this.voteInfo.relateLotteryId
                }
            })
        }
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        },
        loginDialog() {
            return this.$store.getters.getLoginDialog
        },
        authType() {
            return this.$store.getters.getAuthType
        },
        isFlutter() {
            return this.$store.getters.getFlutter
        }
    },
    watch: {
        active(val) {
            console.log(val)
            if (val == 2) {
                this.pageNo = 1
                this.getVoteRankList()
            }
        },
        loginDialog(val) {
            console.log('open', val)
            if (val) {
                this.show = true
            }
        }
    }
}
</script>
<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .VoteTemp-1 {
        max-width: 375px;
        margin: auto;
        min-height: 885px;
        padding-bottom: 126px;
        background-image: url("../../../assets/images/VoteTemp-1/bg.png?imageslim");
        background-size: 100% auto;
        background-repeat: no-repeat;

        .banner {
            min-width: 375px;
            width: 100%;
            display: block;
            height: auto;
        }

        /*致敬劳动者评选活动*/

        .starCon {
            width: 359px;
            min-height: 175px;
            background: #FFFFFF;
            border-radius: 2px;
            margin: 8px auto 8px;
            padding-bottom: 6px;
            border-radius: 4px;

            .starTop {
                width: 327px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                min-height: 30px;
                margin: 0 auto;
                //margin: 0 10px;
                border-bottom: 1px solid #E3E3E3;
                line-height: 30px;
                font-weight: bold;
                padding-top: 14px;

                .star {
                    width: 13px;
                    height: 18px;
                    display: block;
                    margin: 6px 7px 8px 0;
                    //margin: 15px 7px 15px 0;
                }

                .starTitle {
                    word-break: break-all;
                    text-align: left;
                    font-size: 18px;
                }
            }

            .voteNumbers {
                width: 327px;
                margin: 15px auto;
                height: 66px;
                background: @theme;
                border-radius: 4px;
                //margin: 15px 10px;
                display: flex;
                justify-content: space-between;

                .voteNumCon {
                    width: 108px;
                    height: 42px;
                    margin: 11px 0;
                    border-right: 1px solid rgba(255, 255, 255, .5);
                    color: #FFF;

                    .p1 {
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: bold;
                    }

                    .p2 {
                        font-size: 13px;
                        line-height: 18px;
                    }
                }

                .voteNumCon:nth-child(3) {
                    border-right: none;
                }
            }

            .numP {
                color: @theme;
            }
        }

        /*活动规则*/

        .ruleCon {
            width: 359px;
            height: 144px;
            background: #FFFFFF;
            margin: 0 auto 8px;
            border-radius: 4px;
            padding-bottom: 16px;

            .ruleTop {
                display: flex;
                justify-content: center;
                padding-top: 16px;
                margin-bottom: 14px;

                .rule {
                    color: @theme;
                    height: 22px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 22px;
                }

                .arrow {
                    width: 31px;
                    height: 14px;
                    display: block;
                    margin: 4px 5px;
                }
            }

            .ruleItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                line-height: 22px;
                margin: 8px 14px;
                min-height: 22px;
                flex-wrap: wrap;

                .strTime {
                    width: 20px;
                    height: 20px;
                    display: block;
                    margin: 0px 6px 3px 0;
                }

                .ruleP1 {
                    color: #333;
                    margin-right: 10px;
                }

                .ruleP2 {
                    color: #666;
                    text-align: left;
                }

                .ruleP3 {
                    color: #666;
                    margin-left: 20px;
                }
            }
        }

        /*底图*/

        .footBg {
            max-width: 375px;
            margin: auto;
            width: 100%;
            //height: 156px;
            //background-image: url("../../../assets/images/VoteTemp-1/bottomBg.png");
            //background-size: 100% 100%;
            //position: absolute;
            //bottom: 0;
            //left: calc(50% - 187.5px);
            //z-index: -2;

            .footRule {
                width: 100%;
                font-size: 12px;
                color: #666666;
                line-height: 17px;
                text-align: center;
                //margin-top: 50px;
            }
        }

        /*tabbar*/

        .foot {
            .van-tabbar--fixed {
                max-width: 375px !important;
                margin: 0 calc(50% - 187.5px);
                padding-bottom: 20px !important;
            }
        }

        .tabCon {
            max-width: 375px;
            height: auto;
            //margin: 0 15px;
            /*活动介绍*/

            .introCon {
                /*height: 621px;*/
                height: auto;
                padding-bottom: 20px;
                background: #FFFFFF;
                border-radius: 2px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                width: 359px;
                margin: 0 auto;
                border-radius: 4px;

                .introTop {

                    display: flex;
                    justify-content: center;
                    padding: 16px 0 14px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .arrow {
                        width: 31px;
                        height: 14px;
                        display: block;
                        margin: 4px 5px;
                    }
                }

                .introItem1 {
                    margin: 0 16px;
                    text-align: left;
                    word-break: break-all;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 25px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }
                }

                .introItem2 {
                    margin: 0 15px;

                    .introItem {
                        display: flex;
                        justify-content: flex-start;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 22px;
                        margin: 21px 0 5px 0;

                        .dot {
                            width: 8px;
                            height: 8px;
                            display: block;
                            margin: 6px 5px 6px 0;
                        }
                    }

                    .introP1 {
                        margin-left: 13px;
                        text-align: left;
                    }

                    .introP2 {
                        font-size: 12px;
                        font-weight: bold;
                        color: #333333;
                        line-height: 17px;
                        margin: 10px 0 4px;
                    }
                }
            }

            /* 排行榜*/

            .rankCon {
                min-height: 386px;
                width: 359px;
                margin: 0 auto;
                height: auto;
                background: #FFFFFF;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                padding-bottom: 10px;
                border-radius: 4px;

                .introTop {
                    display: flex;
                    justify-content: center;
                    padding: 16px 0 4px;

                    .intro {
                        color: @theme;
                        height: 22px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 22px;
                    }

                    .cup {
                        width: 22px;
                        height: 22px;
                        display: block;
                        margin-right: 4px;
                    }
                }

                .introItem {
                    padding: 0 15px 0 11px;
                    height: 36px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 36px;
                    display: flex;
                    justify-content: space-between;
                    margin: 10px 0;

                    .introItem1 {
                        display: flex;
                        justify-content: flex-start;

                        .cupImg {
                            width: 28px;
                            height: 28px;
                            display: block;
                            margin-top: 3px;
                            margin-right: 24px;
                            line-height: 28px;
                            color: @theme;
                            font-weight: bold;
                            text-align: center;
                        }

                        .cupName {
                            max-width: 208px;
                        }
                    }

                    .cupNum {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .more {
                    margin-top: 16px;
                    color: #999999;
                }
            }

            /*投票*/

            .voteCon {
                width: 359px;
                min-height: 386px;
                border-radius: 4px;
                font-size: 13px;
                color: #666666;
                line-height: 18px;
                margin: 0 auto;

                .voteTop {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    /*margin: 0 15px;*/
                    position: relative;
                    height: 38px;
                    line-height: 38px;
                    width: 359px;
                    margin: 0 auto;
                    box-sizing: border-box;
                    overflow: hidden;
                    border-radius: 4px;

                    .searchImg {
                        width: 22px;
                        height: 23px;
                        position: absolute;
                        left: 12px;
                        top: 8px;
                    }

                    .input {
                        box-sizing: border-box;
                        width: 80%;
                        border: none;
                        outline: none;
                        font-size: 14px;
                        color: #999999;
                        padding: 0 12px 0 36px;
                    }

                    .send {
                        width: 20%;
                        background-color: @theme;
                        color: #fff;
                        text-align: center;
                        //border-radius: 4px;
                    }
                }

                .introItem {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    justify-items: center;
                    margin-top: 8px;
                    width: 359px;
                    //background: white;

                    .voteItem {
                        width: 177px;
                        height: 300px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin: 0 5px 6px 0;
                        position: relative;
                        border-radius: 4px;
                        overflow: hidden;
                        position: relative;

                        //&:nth-of-type(odd) {
                        //    margin-right: 10px;
                        //}

                        .topNum {
                            background-color: rgba(0, 0, 0, .3);
                            //background-image: url("../../../assets/images/VoteTemp-1/topRank.png");
                            background-repeat: no-repeat;
                            border-radius: 4px 0 8px 0;
                            background-size: 100% 100%;
                            //min-width: 33px;
                            height: 23px;
                            position: absolute;
                            left: 0;
                            top: 0;
                            font-size: 12px;
                            color: #FFFFFF;
                            line-height: 24px;
                            padding-left: 8px;
                            padding-right: 8px;
                            text-align: left;
                        }

                        .play {
                            width: 29px;
                            height: 29px;
                            display: block;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: 52px;
                        }

                        .voteHead {
                            width: 177px;
                            height: 132px;
                            border-radius: 2px;
                            object-fit: cover;
                        }

                        .vote-info {
                            position: absolute;
                            left: 0;
                            top: 100px;
                            width: 177px;
                            height: 32px;
                            background: rgba(0, 0, 0, .2);
                            color: white;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-shadow: 0px 0px 3px rgba(0,0,0,0.3);

                            > div {
                                width: 157px;
                                margin: 0 auto;
                            }
                        }

                        .vote-name {
                            width: 157px;
                            height: 36px;
                            line-height: 18px;
                            font-size: 14px;
                            color: #333333;
                            margin: 10px auto 5px;
                        }

                        .vote-num {
                            width: 157px;
                            height: 32px;
                            font-size: 12px;
                            text-align: left;
                            margin: 0 auto;
                            line-height: 32px;
                        }

                        .vote-comp {
                            width: 157px;
                            height: 32px;
                            line-height: 16px;
                            text-align: left;
                            margin: 0 auto;
                        }

                        .vote-btn-red {
                            background: @theme;
                            width: 157px;
                            height: 28px;
                            color: white;
                            margin: 0 auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .voteNum {
                            color: @theme;
                            font-size: 16px;
                            line-height: 22px;
                            margin-top: 8px;
                            font-weight: bold;
                        }

                        .voteTitle {
                            font-size: 12px;
                            color: #333333;
                            line-height: 17px;
                            /*margin-top: 2px;*/
                            width: 85%;
                            margin: 2px auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .voteImg {
                            margin: 10px auto 0;
                            width: 157px;
                            height: 28px;
                            /*margin-top: 10px;*/
                            color: white;
                            background: @theme;
                            line-height: 28px;
                            font-weight: bold;
                        }
                    }

                    .voteItem:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .relate-lottery {
        margin: 16px auto;
        width: 345px;
        height: 48px;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        > div {
            &:nth-of-type(1) {
                font-size: 14px;
            }

            &:nth-of-type(2) {
                font-size: 14px;
                color: white;
                width: 66px;
                height: 22px;
                background: @theme;
            }
        }
    }
    .tine-box {
        color: #666;
        font-size: 14px;
    }

    .tab-image {
        width: 28px;
        height: 28px;
    }

    .tab-box {
        width: 100%;
        margin: 20px auto;
        font-size: 16px;
        /*white-space: nowrap;*/
        overflow-y: auto;
        text-align: left;
        /*height: 40px;*/
        line-height: 40px;
        /*> div {*/
        /*    display: inline-block;*/
        /*    overflow-x: auto;*/
        /*    white-space: nowrap;*/
        /*}*/

        .tab-bar {
            display: inline-block;
            margin: 0 8px;
        }

        .focus-tab {
            font-weight: bold;
            color: @theme;
        }
    }
    // 其他样式也如此
}
</style>
