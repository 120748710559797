import axios from 'axios'
import store from '../../store/index'

let imgDomain = 'https://image.dzplus.dzng.com/'

function toDouble(val) {
    if (isNaN(val)) return
    return val < 10 ? ("0" + val) : val
}

// 上传文件随机key
function fileRandomKey(type='') {
    let date = new Date()
    return `${date.getFullYear()}/${toDouble(date.getMonth() + 1)}/${toDouble(date.getDate())}/activity/${parseInt(new Date().getTime() / 1000)}${Math.random().toString(36).substring(2)}${type}`
}

export async function uploadImage(fileInfo) {
    return new Promise((resolve, reject) => {
        axios.post("https://api.dzplus.dzng.com/gateway/background/manage/upload/qiniu/getUptoken", {
            bucket: "dzplus-images"
        }).then((res) => {
            let token = res.data.data

            // 此时可以自行将文件上传至服务器
            console.log(fileInfo)
            // this.cover = file.content
            let {file} = fileInfo
            let timeKey = new Date().getTime() + file.name
            // console.log(fileInfo)
            let fileData = new FormData()
            fileData.append('token', token)
            fileData.append('key', fileRandomKey(file.type.replace('/', '.')))
            fileData.append('file', file)
            axios.post('https://up.qiniup.com/', fileData).then(data => {
                let {data: {key, hash}} = data
                if (!key) {
                    this.$toast('网络错误,请稍后重试')
                    return false
                }
                resolve(imgDomain + key)
            })
        })
    })

}