<template>
    <div id="DrawTemp-1-Index" :class="theme || 'theme-red-CB0709'" v-scrollT v-title :data-title="info.title && info.title">
        <login v-if="show"></login>
        <!--        <realname v-if="realDialog" @getRealName="getRealNameInfo"></realname>-->
        <van-dialog v-model="dialog">
            <div class="dialog-box"
                 :style="`background: url(${prize.prizeId ? require('../../../assets/images/Draw/dialog-bg-suss.png') : require('../../../assets/images/Draw/dialog-bg-fail.png')})`">
                <div class="info" v-if="prize.prizeId">获得{{ prize.prizeName && prize.prizeName }}</div>
                <div class="info no-pic-info" v-if="!prize.prizeId">
                    <div>差一点就中奖了！</div>
                    <div>哎呀，离中奖只差一点</div>
                </div>
                <img v-if="prize.prizeImg" :src="prize.prizeImg" alt="">
                <div class="dialog-btn">
                    <div @click="initDraw()">返回首页</div>
                    <div @click="toPath('/draw1-list')">中奖记录</div>
                </div>

            </div>
        </van-dialog>
        <div class="content">
            <nav>
                <div></div>
                <div></div>
                <div>
                    <img class="icon" @click="toPath('/draw1-list')"
                         src="https://img.qiluyidian.net/JFRGdp_1651151641482.png" alt="">
                </div>
            </nav>
            <!--            <img class="banner" src="../../../assets/images/Draw/banner-test.png" alt="">-->
            <img class="banner" :style="!info.themeImg && `height: 240px}`" :src="info.themeImg && info.themeImg"
                 alt="">
            <div class="content-box">
                <div class="box-top" v-if="info.actStatus === '1'">
                    <div class="tips">
                        今日还有
                        <span>{{ info.participateCountLeft || 0 }}</span>
                        次抽奖机会
                    </div>
                    <div class="game-content" v-if="game">

                        <ScrapingCard ref="ScrapingCard"
                                      v-if="info.lotteryType === '1'"
                                      @getScrapingCardGif="showGif"
                                      @startDraw="gifDraw('ScrapingCard')"/>

                        <BlindBox v-if="info.lotteryType === '2'"
                                  ref="BlindBox"
                                  class="game-box"
                                  @startDraw="gifDraw('BlindBox')"
                                  @getScrapingCardGif="showGif"/>

                        <EggGame v-if="info.lotteryType === '3'"
                                 ref="EggGame"
                                 class="game-box"
                                 @startDraw="gifDraw('EggGame')"
                                 @getScrapingCardGif="showGif"/>

                        <Nine2 v-if="info.lotteryType === '4'"
                               class="nine2"
                               ref="nine2"
                               @getScrapingCardGif="showGif"
                               @startDraw="gifDraw('nine2')"
                               :prizeList="info.prizeList"/>


                        <Nine v-if="info.lotteryType === '5'"
                              ref="nine"
                              @getScrapingCardGif="showGif"
                              @startDraw="gifDraw('nine')"
                              :prizeList="info.prizeList"/>
                    </div>


                    <!--                    <vue-scratchable>-->
                    <!--                        <div class="draw-area">-->
                    <!--                            <div class="draw-area">-->
                    <!--                                <img src="../../../assets/images/Draw/temp-btn.png" alt="">-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </vue-scratchable>-->


                    <!--                    <div class="draw-area" @click="draw">-->
                    <!--                        <img src="../../../assets/images/Draw/temp-btn.png" alt="">-->
                    <!--                    </div>-->
                </div>
                <div v-else>{{ info.actStatus | formatterActStatus }}</div>
                <div class="box-bottom">
                    <div class="ruleCon">
                        <div class="ruleTop">
                            <img class="arrow" src="https://img.qiluyidian.net/tYXScd_1651136122777.png" alt="">
                            <span class="rule">活动规则</span>
                            <img class="arrow" src="https://img.qiluyidian.net/yyhi73_1651136166272.png" alt="">
                        </div>
                        <div class="box-text text-content html" v-html="info.introduction"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import login from '../../../components/login'
    import realname from '../../../components/realname'
    import {getLotteryInfo, drawLotteryPrize} from '../../../assets/api/draw'
    import wxShare from '../../../assets/js/wx-share'
    import {getIsApp, isWeixin, loginWx, WXauthorizeWx} from '../../../assets/js/wx-authorize'
    import {getRealName, bindingRealName} from "../../../assets/api/user"
    import {Toast, NavBar} from "vant";
    import {formatterActStatus} from "../../../assets/js/untils";
    import Nine from '../components/Nine'
    import Nine2 from '../components/Nine-2'
    import ScrapingCard from '../components/ScrapingCard'
    import EggGame from '../components/EggGame'
    import BlindBox from '../components/BlindBox'

    export default {
        name: "VoteTemp-1",
        components: {
            login,
            NavBar,
            realname,
            Nine,
            ScrapingCard,
            EggGame,
            Nine2,
            BlindBox
        },
        data() {
            return {
                // 数据访问量啥啥啥
                count: {},
                //距离结束的时间
                //选项信息分页
                pageNum: 1,
                flag: false,
                noMore: false,
                isLoad: true,
                requestFlag: true,
                //接口返回接收
                groupId: '',
                //排行榜页码
                pageNo: 1,
                moreFlag: true,

                //    介绍隐藏
                showIntro: false,
                //    排行隐藏
                showRank: false,
                //    投票隐藏
                searchCon: '',
                show: false,
                id: '1',
                info: {},
                dialog: false,
                realName: '',
                realDialog: false,
                prize: {},
                game: false
            }
        },
        created() {
            this.getParams()
        },
        async mounted() {
            const status = await this.getLogin()
            console.log(status)
            if (status) {
                this.getParams()
                // setTimeout(() => {
                //     this.game = true
                // }, 500)

            }
            // this.$nextTick(() => {
            //     setTimeout(() => {
            //         this.game = true
            //     }, 500)
            //     // this.game = true
            // })
        },
        methods: {
            async getParams() {
                console.log(this.$route.query)
                this.id = this.$route.query.lotteryId
                this.getLotteryInfo()
                // this.getVoteStatistInfo()
                // this.getVoteRankList()
            },
            getLogin() {
                return new Promise((resolve) => {
                    if (getIsApp()) {
                        if (this.isFlutter) {
                            window.h5SendAppToken = (data) => {
                                if (data) {
                                    this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                                    resolve('flutter')
                                    // this.deSubmit()
                                }
                            }
                            window.flutter_inappwebview.callHandler('h5GetApptoken')
                        }
                        this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                            console.log(data)
                            if (data) {
                                this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                                // this.getParams()
                                // this.getParams()
                                resolve('app')
                            }
                        })
                        this.$bridge.callHandler('h5GetApptoken')
                    } else if (!this.token) {
                        // this.getParams()
                        if (isWeixin()) {
                            loginWx()
                            resolve('wxNoToken')
                        } else {
                            this.show = true
                            resolve('h5NoToken')
                            // this.getParams()
                        }
                    } else {
                        resolve('h5')
                        // this.getParams()
                        // this.getParams()
                    }
                })
            },
            onChange(index) {
                this.active = index
                console.log(index)
            },
            initDraw(type) {
                this.dialog = false
                // case '1':
                //     name = '刮刮乐'
                //     break
                // case '2':
                //     name = '盲盒'
                //     break
                // case '3':
                //     name = '砸金蛋'
                //     break
                // case '4':
                //     name = '九宫格'
                //     break
                // case '5':
                //     name = '大转盘'
                //     break
                //     this.$refs.EggGame.init()

                switch (this.info.lotteryType) {
                        // 刮刮卡
                    case '1':
                        this.$refs.ScrapingCard.init()
                        break
                    case '2':
                        this.$refs.BlindBox.init()
                        break
                        // 金蛋
                    case '3':
                        this.$refs.EggGame.init()

                        break
                        // case '4':
                        //     this.$refs.nine2.init()
                        //     break
                        // case '5':
                        //     this.$refs.nine.init()
                        //     break
                }
            },
            async getLotteryInfo() {
                let {code, msg, data} = await getLotteryInfo(this.id)
                if (code === 200) {
                    this.info = data
                    // this.getRealName()
                    console.log(data)
                    this.$nextTick(() => {
                        setTimeout(() => {
                            console.log('game')
                            this.game = true
                        }, 500)
                        // this.game = true
                    })
                    if (isWeixin()) {
                        let {title, weShareSummary, weShareImg} = data
                        wxShare.loadWxConfig(title, weShareSummary, window.location.href, weShareImg)
                    }
                } else {
                    this.$message.error(msg)
                }
            },
            async getRealName() {
                let {code, data} = await getRealName()
                if (code === 200) {
                    this.realName = data
                }

            },
            showGif() {
                this.dialog = true
            },
            async gifDraw(type) {
                const status = await this.getLogin()
                console.log(status)
                if (status !== 'wxNoToken' && status !== 'h5NoToken') {
                    let gameRef = this.$refs[type]
                    let {code, data, msg} = await drawLotteryPrize(this.id)
                    if (code === 200) {
                        this.getLotteryInfo()
                        console.log(data.prizeId)
                        if (data.prizeId !== -1) {
                            this.prize = data
                            gameRef.sendGif(data)
                            console.log(this.prize)
                        } else {
                            gameRef.sendGif('')
                        }
                        // this.dialog = true
                    } else {
                        this.initDraw()
                        this.$toast(msg)
                    }
                }
                if (!this.realName) {
                    this.realDialog = true
                } else {
                    let gameRef = this.$refs[type]
                    let {code, data, msg} = await drawLotteryPrize(this.id)
                    if (code === 200) {
                        this.getLotteryInfo()
                        console.log(data.prizeId)
                        if (data.prizeId !== -1) {
                            this.prize = data
                            gameRef.sendGif(data)
                            console.log(this.prize)
                        } else {
                            gameRef.sendGif('')
                        }
                        // this.dialog = true
                    } else {
                        this.$toast(msg)
                    }
                }

                // if (!this.token) {
                //     if (isWeixin()) {
                //         loginWx()
                //     } else {
                //         console.log(22)
                //         this.show = true
                //     }
                // } else {
                //
                //     let gameRef = this.$refs[type]
                //     let {code, data, msg} = await drawLotteryPrize(this.id)
                //     if (code === 200) {
                //         this.getLotteryInfo()
                //         console.log(data.prizeId)
                //         if (data.prizeId !== -1) {
                //             this.prize = data
                //             gameRef.sendGif(data)
                //             console.log(this.prize)
                //         } else {
                //             gameRef.sendGif('')
                //         }
                //         // this.dialog = true
                //     } else {
                //         this.initDraw()
                //         this.$toast(msg)
                //     }
                // if (!this.realName) {
                //     this.realDialog = true
                // } else {
                //     let gameRef = this.$refs[type]
                //     let {code, data, msg} = await drawLotteryPrize(this.id)
                //     if (code === 200) {
                //         this.getLotteryInfo()
                //         console.log(data.prizeId)
                //         if (data.prizeId !== -1) {
                //             this.prize = data
                //             gameRef.sendGif(data)
                //             console.log(this.prize)
                //         } else {
                //             gameRef.sendGif('')
                //         }
                //         // this.dialog = true
                //     } else {
                //         this.$toast(msg)
                //     }
                // }
                // }
            },
            async draw() {
                const status = await this.getLogin()
                if (status !== 'wxNoToken' && status !== 'h5NoToken') {
                    let {code, data, msg} = await drawLotteryPrize(this.id)
                    if (code === 200) {
                        this.getLotteryInfo()
                        console.log(data.prizeId)
                        if (data.prizeId !== -1) {
                            this.prize = data
                            console.log(this.prize)
                        } else {

                        }
                        this.dialog = true
                    } else {
                        this.$toast(msg)
                    }
                }

                // if (!this.token) {
                //     if (isWeixin()) {
                //         loginWx()
                //     } else {
                //         console.log(22)
                //         this.show = true
                //     }
                // } else {
                //     let {code, data, msg} = await drawLotteryPrize(this.id)
                //     if (code === 200) {
                //         this.getLotteryInfo()
                //         console.log(data.prizeId)
                //         if (data.prizeId !== -1) {
                //             this.prize = data
                //             console.log(this.prize)
                //         } else {
                //
                //         }
                //         this.dialog = true
                //     } else {
                //         this.$toast(msg)
                //     }
                // if (!this.realName) {
                //     this.realDialog = true
                // } else {
                //     let {code, data, msg} = await drawLotteryPrize(this.id)
                //     if (code === 200) {
                //         this.getLotteryInfo()
                //         console.log(data.prizeId)
                //         if (data.prizeId !== -1) {
                //             this.prize = data
                //             console.log(this.prize)
                //         } else {
                //
                //         }
                //         this.dialog = true
                //     } else {
                //         this.$toast(msg)
                //     }
                // }
                // }
            },
            getRealNameInfo(realName) {
                console.log(realName)
                this.realName = realName
                this.realDialog = false
                this.$toast('绑定成功')
            },
            async toPath(path) {
                const status = await this.getLogin()
                if (status !== 'wxNoToken' && status !== 'h5NoToken') {
                    this.$router.push({
                        path,
                        query: {
                            id: this.id
                        }
                    })
                }

                // if (this.token) {
                //     this.$router.push({
                //         path,
                //         query: {
                //             id: this.id
                //         }
                //     })
                // } else {
                //     if (isWeixin()) {
                //         loginWx()
                //     } else {
                //         this.show = true
                //     }
                // }

            },
        },
        watch: {

            show(val) {
                console.log(val)
                if (!val) {
                    this.getLotteryInfo()
                }
            },
            dialog(val) {
                console.log(val)
                if (!val) {
                    this.prize = {}
                }
            },
            loginDialog(val) {
                console.log('open', val)
                if (val) {
                    this.show = true
                }
            },
            token(val) {
                if (val) {
                    this.getRealNameInfo()
                    this.getLotteryInfo()
                }
            },
            async isFlutter() {
                const status = await this.getLogin()
                console.log(status)
                if (status) {
                    this.getParams()
                }
                // this.getLotteryInfo()
                // this.initDraw()
            }
        },
        computed: {
            theme() {
                return this.$store.getters.getTheme
            },
            token() {
                return this.$store.getters.getToken
            },
            loginDialog() {
                return this.$store.getters.getLoginDialog
            },
            isFlutter() {
                return this.$store.getters.getFlutter
            }
        },
        filters: {
            formatterActStatus
        }

    }
</script>

<style lang="less">
    #DrawTemp-1-Index {
        .van-dialog {
            background-color: rgba(0, 0, 0, 0) !important;
        }

        .van-dialog__footer {
            display: none;
        }
    }
</style>

<style scoped lang="less">
    @import "../../../assets/css/theme";

    .dialog-box {
        width: 320px;
        height: 420px;
        background: url("../../../assets/images/Draw/dialog-bg-suss.png");
        background-size: 100% 100% !important;
        //padding-top: 130px;
        box-sizing: border-box;
        padding-top: 70px;

        .info {
            margin: 0 auto 10px;


            > div {
                &:nth-of-type(1) {
                    font-size: 24px;
                    color: #8C0209;
                    margin: 0 auto 18px;
                }

                &:nth-of-type(2) {
                    font-size: 14px;
                    color: #8C0209;
                }
            }
        }

        .no-pic-info {
            height: 170px;
            padding-top: 22px;
            box-sizing: border-box;
        }

        > img {
            width: 150px;
            height: 150px;
            background-color: white;
        }

        .dialog-btn {
            width: 80%;
            margin: 100px auto 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;


            > div:nth-of-type(1) {
                width: 120px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                //border: 1px solid rgb(232,129,60);
                border-radius: 20px;
                //color: rgb(232,129,60);
                color: #FFF2E2;
                border: 1px solid #FFF2E2;
            }

            > div:nth-of-type(2) {
                width: 120px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgb(232, 129, 60);
                border-radius: 20px;
                background-color: rgb(232, 129, 60);
                color: white;
            }
        }

    }

    .module-theme(@theme, @tag) {

        .content {
            max-width: 375px;
            margin: auto;
            min-height: 885px;
            padding-bottom: 126px;
            background-image: url("../../../assets/images/VoteTemp-1/bg.png");
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: relative;

            nav {
                width: 375px;
                height: 45px;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: white;

                > div {
                    width: 73px;
                }

                .icon {
                    margin-top: 8px;
                    width: 73px;
                }
            }

            .banner {
                min-width: 375px;
                width: 100%;
                object-fit: contain;
                margin-bottom: 10px;
                //display: block;
                //height: auto;
            }

            .content-box {
                width: 95%;
                margin: 0 auto;

                .game-content {
                    width: 100%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                }

                .box-top {
                    width: 355px;
                    min-height: 326px;
                    background: white;
                    //background: url("../../../assets/images/Draw/draw-bg.png");
                    background-size: 100% 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    padding: 20px 0 0;
                    border-radius: 4px;

                    .game-box {
                        margin: 30px auto !important;
                    }


                    //.nine2 {
                    //    margin: 10px auto 0!important;
                    //}

                    .tips {
                        width: 195px;
                        height: 20px;
                        background: #F4EFEF;
                        border-radius: 10px;
                        font-size: 12px;
                        color: #999999;
                        line-height: 20px;
                        margin-bottom: 10px;

                        > span {
                            color: rgba(229, 30, 53, 1);
                        }
                    }

                    .draw-area {
                        width: 330px;
                        height: 130px;
                        background: url("../../../assets/images/Draw/temp1-draw-bg.png");
                        background-size: 100% 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //z-index: 200;

                        > img {
                            width: 117px;
                            height: 35px;
                        }
                    }
                }


                .box-bottom {
                    margin-top: 10px;
                    border-radius: 5px;
                    background: white;
                    width: 100%;
                    min-height: 180px;
                    //padding-top: 40px;
                    box-sizing: border-box;

                    .ruleCon {
                        min-height: 144px;
                        background: #FFFFFF;
                        border-radius: 2px;
                        margin: 0 15px 20px;
                        padding-bottom: 20px;

                        .ruleTop {
                            display: flex;
                            justify-content: center;
                            padding-top: 15px;

                            .rule {
                                color: #E8813C;
                                height: 22px;
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 22px;
                            }

                            .arrow {
                                width: 31px;
                                height: 14px;
                                display: block;
                                margin: 4px 5px;
                            }
                        }

                        .ruleItem {
                            display: flex;
                            justify-content: flex-start;
                            font-size: 14px;
                            line-height: 20px;
                            margin: 8px 14px;
                            height: 20px;

                            .strTime {
                                width: 14px;
                                height: 14px;
                                display: block;
                                margin: 3px 6px 3px 0;
                            }

                            .ruleP1 {
                                color: #333;
                                margin-right: 10px;
                            }

                            .ruleP2 {
                                color: #666;
                            }

                            .ruleP3 {
                                color: #666;
                                margin-left: 20px;
                            }
                        }
                    }

                    .box-text {
                        width: 315px;
                        margin: 20px auto;
                        text-align: left;
                        font-size: 12px;
                        word-break: break-all;
                    }
                }
            }
        }

    }
</style>
