import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/common.less'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/js/rem'
import VueLuckyCanvas from '@lucky-canvas/vue'
import bridge from './assets/js/bridge'

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueLuckyCanvas)

Vue.config.productionTip = false

Vue.directive('scrollT', {
    inserted: function (el, binding) {
        console.log('scrollT')
        Vue.nextTick(() => { // 在dom元素执行完之后执行
            window.scrollTop = 0
        })

        // window.scrollTo(0, 0)
    }
})

Vue.directive('title', {
    update: function (el, binding) {
        document.title = el.dataset.title
    }
})

Vue.prototype.$bridge = bridge


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
