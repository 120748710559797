<template>
    <div id="SignTemp-1-Details" :class="theme || 'theme-red-CB0709'">
        <van-overlay :show="dialog" z-index="2002">
            <div class="dialog-box">
                <van-loading vertical color="#0094ff">支付中...</van-loading>
            </div>

        </van-overlay>
        <div class="SignTemp-1-Details">
            <van-nav-bar
                    title="报名信息填写"
                    left-arrow
                    @click-left="back"
            />
            <div class="input-box">
                <van-form>
                    <div class="input-item"
                         v-for="(item, index) of inputList"
                         :key="index">
                        <div> {{ item.isNecessary === '1' ? '*' : '' }} {{
                                item.labelName
                            }}
                        </div>
                        <van-field
                                v-model="item.answer"
                                v-if="item.formType == 1"
                                placeholder="请输入内容"
                        />

                        <el-input
                                v-if='item.formType == 2'
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="item.answer">
                        </el-input>
                        <van-radio-group v-model="item.answer" v-if='item.formType == 4'>
                            <van-radio v-for="(radio, index) of item.activityValue.split(',')"
                                       :key="index"
                                       :name="radio"
                                       checked-color="#ee0a24">
                                {{ radio }}
                            </van-radio>
                            <!--                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>-->
                        </van-radio-group>
                        <van-uploader
                                v-model="item.picList"
                                :max-count="8"
                                v-if='item.formType == 5'
                                :after-read="(file) => afterRead(file, item)"
                                :before-read="imageBefore"
                                :before-delete="(file, detail) => deleteImage(detail, item)"/>
                        <van-checkbox-group v-model="item.answer" v-if='item.formType == 9'>
                            <van-checkbox
                                    v-for="(check, index) of item.activityValue.split(',')"
                                    :name="check"
                                    shape="square">{{ check }}
                            </van-checkbox>
                        </van-checkbox-group>
                    </div>
                    <!--                    <van-field-->
                    <!--                            v-model="test"-->
                    <!--                            name="用户名"-->
                    <!--                            label="用户名"-->
                    <!--                            placeholder="用户名"-->
                    <!--                            :rules="[{ required: true, message: '请填写用户名' }]"-->
                    <!--                    />-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <el-input-->
                    <!--                                type="textarea"-->
                    <!--                                :rows="2"-->
                    <!--                                placeholder="请输入内容"-->
                    <!--                                v-model="textarea">-->
                    <!--                        </el-input>-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-uploader v-model="fileList"-->
                    <!--                                :after-read="(file) => afterRead(file, 'fileList')"-->
                    <!--                                multiple />-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-radio-group v-model="radio">-->
                    <!--                            <van-radio name="1" checked-color="#ee0a24">单选框 1</van-radio>-->
                    <!--                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>-->
                    <!--                        </van-radio-group>-->
                    <!--                    </div>-->
                    <!--                    <div class="input-item">-->
                    <!--                        <div>题目</div>-->
                    <!--                        <van-checkbox-group v-model="result">-->
                    <!--                            <van-checkbox name="a" shape="square">复选框 a</van-checkbox>-->
                    <!--                            <van-checkbox name="b" shape="square">复选框 b</van-checkbox>-->
                    <!--                        </van-checkbox-group>-->
                    <!--                    </div>-->


                </van-form>
            </div>
            <div class="bottom-btn">
                <div style="opacity: 0">
                    费用
                    <span>¥{{ registFee }}</span>
                </div>
                <div @click="deSubmit">提交</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {uploadImage} from "../../../assets/js/upload";
import {checkPay, getRegistItem, submitRegist} from "../../../assets/api/sign";
import lodash from "lodash";

export default {
    name: "Details",
    computed: {
        ...mapGetters({
            theme: "getTheme"
        })
    },
    data() {
        return {
            test: '',
            testArr: [],
            fileList: [],
            id: '',
            inputList: [],
            preImg: [],
            arrTypeList: ['9', '5'],
            registFee: '',
            lotteryId: '',
            dialog: false
        }
    },
    methods: {
        getParams() {
            this.id = this.$route.query.id
            this.registFee = this.$route.query.registFee
            this.getRegistItem()
        },
        back() {
            this.$router.back()
        },
        async afterRead(file, item) {
            let imgUrl = await uploadImage(file)
            item.answer.push(imgUrl)
            // item.answer = imgUrl + '$$split$$'
            // this.fileList.push({url: imgUrl})
        },
        imageBefore(file) {
            if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name)) {
                this.$message.error("只支持jpeg、png、jpg、gif类型的文件");
                return false
            }else {
                return true
            }

        },
        deleteImage(detail, item) {
            console.log(detail)
            item.answer.splice(detail.index, 1)
            return true
        },
        async afterReadVideo(file, item) {
            console.log(111, file.file)
            if(file.file.type !== 'video/mp4') {
                this.$message.error('请上传MP4格式视频')
                return false
            }
            this.$set(item, 'load', true)
            let url = await uploadImage(file)
            // console.log(url)
            // console.log(item)
            this.$set(item, 'load', false)
            item.answer = url
        },

        async getRegistItem() {
            let {data, code} = await getRegistItem({registId: this.id})
            if (code == 200) {
                // data
                this.inputList = data.map(item => {
                    if (item.formType == 9 || item.formType == 5) {
                        item.answer = []
                    } else {
                        item.answer = ''
                    }
                    return item
                })
            }
            // this.fileList.push({url: imgUrl})
        },
        deSubmit: lodash.debounce(function () {
            this.submit()
        }, 200),
        async submit() {
            this.dialog = true
            console.log(1111)
            let form = {}
            let flag = true
            this.inputList.map(item => {
                if (item.isNecessary == 1) {
                    if (!item.answer || item.answer.length == 0) {
                        flag = false
                    }
                }
                console.log(item.keyName)
                form[item.keyName] = this.arrTypeList.indexOf(item.formType) != -1 ? item.answer.join(',') : item.answer

            })
            if (flag) {
                console.log(form)
                let {code, msg, data: {lotteryId, payQueryId}} = await submitRegist({registId: this.id, ...form})
                if (code == 200) {

                    // if (lotteryId) {
                    //     this.lotteryId = lotteryId
                    //
                    //     this.$dialog.confirm({
                    //         title: '报名成功',
                    //         message: '已成功报名，恭喜您获得一次抽奖机会，可点击下方“前往抽奖”按钮进入抽奖页面。',
                    //         confirmButtonText: '前往抽奖'
                    //     })
                    //             .then(() => {
                    //                 // on confirm
                    //                 this.$router.replace({
                    //                     path: '/draw1',
                    //                     query: {
                    //                         lotteryId
                    //                     }
                    //                 })
                    //             })
                    //             .catch(() => {
                    //                 this.$router.back()
                    //                 // on cancel
                    //             });
                    // }else {
                    //     this.$router.back()
                    // }
                    // this.$router.back()
                    let _this = this
                    let checkPayment = () => {
                        let count = 0
                        let timer = null
                        timeCheck()
                        async function timeCheck() {
                            let {code, data} = await checkPay({
                                payQueryId
                            })
                            timer = setTimeout(() => {
                                timeCheck()
                            }, 2000)
                            if(code === 200) {
                                count ++
                                if(data) {
                                    clearInterval(timer)
                                    if (lotteryId) {
                                        _this.lotteryId = lotteryId
                                        _this.$dialog.confirm({
                                            title: '报名成功',
                                            message: '已成功报名，恭喜您获得一次抽奖机会，可点击下方“前往抽奖”按钮进入抽奖页面。',
                                            confirmButtonText: '前往抽奖'
                                        })
                                                .then(() => {
                                                    // on confirm
                                                    _this.$router.replace({
                                                        path: '/draw1',
                                                        query: {
                                                            lotteryId
                                                        }
                                                    })
                                                })
                                                .catch(() => {
                                                    _this.$router.back()
                                                    // on cancel
                                                });
                                    }else {
                                        _this.$router.back()
                                    }
                                } else if(count > 20) {
                                    clearInterval(timer)
                                    count = 0
                                    _this.dialog = false
                                    _this.$message.error('付款失败')
                                }
                            }else {
                                clearInterval(timer)
                                _this.$message.error('系统错误')
                            }
                        }

                    }
                    if(payQueryId) {
                        checkPayment()
                    }else {
                        this.dialog = false
                        if (lotteryId) {
                            this.lotteryId = lotteryId
                            this.$dialog.confirm({
                                title: '报名成功',
                                message: '已成功报名，恭喜您获得一次抽奖机会，可点击下方“前往抽奖”按钮进入抽奖页面。',
                                confirmButtonText: '前往抽奖'
                            })
                                    .then(() => {
                                        // on confirm
                                        this.$router.replace({
                                            path: '/draw1',
                                            query: {
                                                lotteryId
                                            }
                                        })
                                    })
                                    .catch(() => {
                                        this.$router.back()
                                        // on cancel
                                    });
                        }else {
                            this.$router.back()
                        }
                    }

                } else {
                    this.dialog = false
                    this.$message.error(msg)
                }
            } else {
                this.dialog = false
                this.$message.error('请填写所有必填项')
            }


            // console.log(this.inputList.map(item => {
            //     return {[item.keyName]: item.answer}
            // }))
        }
    },
    created() {
        this.getParams()
    }
}
</script>
<style lang="less">
.SignTemp-1-Details {
    .van-nav-bar .van-icon {
        color: rgb(51, 51, 51);
    }

    .van-radio {
        margin: 5px auto;
    }

    .van-checkbox {
        margin: 5px auto;
    }
}

</style>
<style scoped lang="less">
@import "../../../assets/css/theme";

.dialog-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.module-theme(@theme, @tag) {
    .SignTemp-1-Details {
        background: white;
        padding-bottom: 100px;
        max-width: 375px;
        margin: auto;

        .input-box {
            width: 90%;
            margin: 0 auto;

            .input-item {
                padding: 10px 16px;

                > div {
                    text-align: left;
                    margin-bottom: 10px;
                }
            }
        }

        .bottom-btn {
            max-width: 375px;
            transform: translateX(-50%);
            width: 100%;
            height: 60px;
            position: fixed;
            left: 50%;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: white;
            padding: 0 15px;
            box-sizing: border-box;

            > div:nth-of-type(1) {
                span {
                    color: @theme;
                }
            }

            > div:nth-of-type(2) {
                background: @theme;
                color: white;
                width: 30%;
                height: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.video-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > video {
        width: 97%;
        display: inline-block;
    }

    .load {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(255, 255, 255, .5);
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            width: 100%;
        }
    }

    .del {
        position: absolute;
        right: 0;
        top: -25px;
        color: rgba(22, 119, 255, 1);
        z-index: 20;
    }
}

</style>