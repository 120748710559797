import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";
// 李涛——大众号入驻
const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTdhqXjbeWhEiVJdTXUw4F4DYZPVTo572JHoOGXi+Z+FEVRS5LW7R9auy0xAHJPVm4xVBGJSBD7XhY+jRvTzm4VLCoNPdv1bF6L76p9JOVLEoXYWx11UhoFPLc5OYpEfIGjoFwCJ7581XWFIpJ58R6oZPscDMMOcI47PY5Hkih8wIDAQAB";
// 崔啸
const publicKeyCx =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCvT0vHJn/iebE3GIjng5efC67tZrRmBBMB1Y4RlLwP40kgtOh1LWTwp0aPlzuqBDFa2r1tq5AFl7TY1Ve3puAZhyuBIyF74uqlgXTysUtwdKysvD0AwPJyU6tKIYvfXIfaZjqdy+0A743G2gscOHoSTfGj9hD/0mjEWbqo6mXblQIDAQAB";

// RSA加密
export function encrypt(str, from) {
  // 公钥Base64编码:
  let crypt = new JSEncrypt(); // 默认密钥字节长度
  crypt.setPublicKey(from ? publicKeyCx : publicKey); // 公钥加密
  let enStr = crypt.encrypt(str);
  return enStr;
}
// 生成随机数
export function generatekey(num) {
  let library =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let key = "";
  for (let i = 0; i < num; i++) {
    let randomPoz = Math.floor(Math.random() * library.length);
    key += library.substring(randomPoz, randomPoz + 1);
  }
  return key;
}
//字符串解密方法
export function decryptAes(str, _IV) {
  let key = CryptoJS.enc.Utf8.parse(_IV);
  let iv = CryptoJS.enc.Utf8.parse(_IV);
  let encryptedHexStr = CryptoJS.enc.Hex.parse(str);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
